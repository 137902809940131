import {castDraft, original} from 'immer'

import type {Draft, PayloadAction} from '@reduxjs/toolkit'
import {createReducer, isAnyOf} from '@reduxjs/toolkit'
import type {ActionReducerMapBuilder} from '@reduxjs/toolkit/src/mapBuilders'
import deepEqual from 'fast-deep-equal'
import merge from 'lodash/merge'

import {
  compatibleAgents,
  receiveBuildAction,
  receiveBuildChanges,
  receiveBuildWSDataAction,
} from '../actions/builds'
import {removeAgentAction} from '../actions/fetch'
import {buildArtifacts} from '../components/common/BuildArtifacts/BuildArtifacts.slice'
import {
  commentBuild,
  tagBuild,
} from '../components/common/Builds/Build/BuildActions/BuildActions.actions'
import {pinBuild} from '../components/common/Builds/Build/PinBuild/PinBuild.actions'
import {markAgentDeleted} from '../components/pages/AgentsPages/AgentsPages.actions'
import type {Entities, Normalized} from '../rest/schemata'
import type {BuildType, ProblemOccurrence, TestOccurrence} from '../services/rest'
import {getIdFromLocator, restApi} from '../services/rest'
import type {
  AgentId,
  BuildId,
  BuildTypeId,
  ChangeId,
  NormalizedAgentPreview,
  NormalizedAgent,
  NormalizedBuild,
  NormalizedChange,
  NormalizedProject,
  ProjectId,
  SnapshotDependenciesIDList,
  TestOccurrenceId,
  WebLinks,
  ProblemOccurrenceId,
} from '../types'
import {toBuildId, toBuildTypeId} from '../types'
import {getEmptyHash} from '../utils/empty'
import type {KeyValue} from '../utils/object'
import {objectEntries} from '../utils/object'

import {combineReducers} from './combineReducers'
import {assignIfDeepDifferent} from './utils'

const buildAgentReducers = (
  builder: ActionReducerMapBuilder<KeyValue<AgentId, NormalizedAgent | null | undefined>>,
) => {
  builder.addMatcher(restApi.endpoints.deleteAgent.matchFulfilled, (state, action) => {
    const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
    delete state[agentId]
  })
  builder.addMatcher(restApi.endpoints.setAgentPool.matchFulfilled, (state, action) => {
    const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
    const agent = state[agentId]
    if (agent != null) {
      agent.pool = castDraft(action.payload)
    }
  })
  builder.addMatcher(restApi.endpoints.setAuthorizedInfo.matchFulfilled, (state, action) => {
    const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
    const agent = state[agentId]
    if (agent != null) {
      agent.authorized = action.payload.status
      agent.authorizedInfo = action.payload
    }
  })
  builder.addMatcher(restApi.endpoints.setEnabledInfo.matchFulfilled, (state, action) => {
    const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
    const agent = state[agentId]
    if (agent != null) {
      agent.enabled = action.payload.status
      agent.enabledInfo = action.payload
    }
  })
}

export default combineReducers<Entities>({
  agents: createReducer<KeyValue<AgentId, NormalizedAgent | null | undefined>>({}, builder => {
    builder.addCase(removeAgentAction, (state, action) => {
      delete state[action.payload]
    })
    buildAgentReducers(builder)
    builder.addMatcher(restApi.endpoints.getAllAgentsNormalized.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.agents)
    })
  }),

  agentPreviews: createReducer<KeyValue<AgentId, NormalizedAgentPreview | null | undefined>>(
    {},
    builder => {
      builder.addCase(removeAgentAction, (state, action) => {
        delete state[action.payload]
      })
      builder.addMatcher(restApi.endpoints.deleteAgent.matchFulfilled, (state, action) => {
        const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
        delete state[agentId]
      })
      builder.addMatcher(restApi.endpoints.setAgentPool.matchFulfilled, (state, action) => {
        const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
        const agent = state[agentId]
        if (agent != null) {
          agent.pool = castDraft(action.payload)
        }
      })
      builder.addMatcher(restApi.endpoints.setAuthorizedInfo.matchFulfilled, (state, action) => {
        const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
        const agent = state[agentId]
        if (agent != null) {
          agent.authorized = action.payload.status
        }
      })
      builder.addMatcher(restApi.endpoints.setEnabledInfo.matchFulfilled, (state, action) => {
        const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
        const agent = state[agentId]
        if (agent != null) {
          agent.enabled = action.payload.status
        }
      })
      builder.addMatcher(
        isAnyOf(
          restApi.endpoints.getAllAgentPreviewsNormalized.matchFulfilled,
          restApi.endpoints.getAllAgentsNormalized.matchFulfilled,
        ),
        (state, action) => action.payload.entities.agentPreviews || getEmptyHash(),
      )
    },
  ),

  agent: createReducer<KeyValue<AgentId, NormalizedAgent | null>>({}, builder => {
    builder.addCase(markAgentDeleted, (state, action) => {
      const agent = state[action.payload]
      if (agent != null) {
        agent.deleted = true
      }
    })
    builder.addCase(removeAgentAction, (state, action) => {
      delete state[action.payload]
    })
    builder.addMatcher(restApi.endpoints.getAgentNormalized.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.agent.entities.agents)
    })
    buildAgentReducers(builder)
  }),

  cloudImage: createReducer({}, builder => {
    builder.addMatcher(restApi.endpoints.getAgentNormalized.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.agent.entities.cloudImage)
    })
    builder.addMatcher(restApi.endpoints.getAllAgentsNormalized.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.cloudImage)
    })
  }),

  buildTypes: createReducer<KeyValue<BuildTypeId, BuildType>>({}, builder => {
    function mergeBuildTypes(
      state: Draft<KeyValue<BuildTypeId, BuildType>>,
      buildTypes: KeyValue<BuildTypeId, BuildType>,
    ) {
      objectEntries(buildTypes).forEach(([id, buildType]) => {
        const buildTypeId = toBuildTypeId(id)
        const existingBuildType = original(state)?.[buildTypeId] as BuildType
        if (existingBuildType != null && buildType != null) {
          objectEntries(buildType).forEach(([key, value]) => {
            if (!deepEqual(existingBuildType[key], value)) {
              const prevBuildType = state[buildTypeId] as Record<string, unknown>
              prevBuildType[key] = value
            }
          })
        } else {
          state[buildTypeId] = castDraft(buildType)
        }
      })
    }
    builder.addMatcher(restApi.endpoints.getAllBuildsNormalized.matchFulfilled, (state, action) => {
      if (action.meta.arg.originalArgs.withBuildTypeDetails && action.payload.entities.buildTypes) {
        mergeBuildTypes(state, action.payload.entities.buildTypes)
      }
    })
    builder.addMatcher(
      restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.withBuildTypes) {
          Object.assign(state, action.payload.entities.buildTypes)
        }
      },
    )
    builder.addMatcher(restApi.endpoints.getProjectNormalized.matchFulfilled, (state, action) => {
      if (action.meta.arg.originalArgs.withBuildTypes) {
        assignIfDeepDifferent(state, action.payload.entities.buildTypes)
      }
    })
    builder.addMatcher(restApi.endpoints.getBuildTypeNormalized.matchFulfilled, (state, action) =>
      assignIfDeepDifferent(state, action.payload.entities.buildTypes),
    )
    builder.addMatcher(
      restApi.endpoints.getAllBuildTypesNormalized.matchFulfilled,
      (state, action) => {
        merge(state, action.payload.entities.buildTypes)
      },
    )
    builder.addMatcher(restApi.endpoints.getAllAgentsNormalized.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.buildTypes)
    })
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getBuildNormalized.matchFulfilled,
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled,
        receiveBuildAction.match,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withBuildTypes && action.payload.entities.buildTypes) {
          mergeBuildTypes(state, action.payload.entities.buildTypes)
        }
      },
    )
  }),

  buildTypeParameters: createReducer({}, builder => {
    builder.addMatcher(restApi.endpoints.getBuildTypeNormalized.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.buildTypeParameters)
    })
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withParameters) {
          Object.assign(state, action.payload.entities.buildTypeParameters)
        }
      },
    )
  }),

  buildTypeLinks: createReducer({}, builder => {
    const mergeBuildTypeLinks = (
      state: Draft<KeyValue<BuildTypeId, WebLinks>>,
      action: PayloadAction<Normalized<unknown>>,
    ) => {
      Object.assign(state, action.payload.entities.buildTypeLinks)
    }
    builder.addMatcher(restApi.endpoints.getBuildTypeNormalized.matchFulfilled, mergeBuildTypeLinks)
    builder.addMatcher(restApi.endpoints.getAllAgentsNormalized.matchFulfilled, (state, action) => {
      mergeBuildTypeLinks(state, action)
    })
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getBuildNormalized.matchFulfilled,
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled,
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        receiveBuildAction,
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withBuildTypes) {
          mergeBuildTypeLinks(state, action)
        }
      },
    )
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withLinks) {
          mergeBuildTypeLinks(state, action)
        }
      },
    )
  }),

  buildTypeDescription: createReducer({}, builder => {
    builder.addMatcher(restApi.endpoints.getBuildTypeNormalized.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.buildTypeDescription)
    })
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withDescription) {
          Object.assign(state, action.payload.entities.buildTypeDescription)
        }
      },
    )
  }),

  projects: createReducer<KeyValue<ProjectId, NormalizedProject>>({}, builder => {
    builder.addMatcher(
      restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
      (state, action) => {
        merge(state, action.payload.entities.projects)
      },
    )
    builder.addMatcher(restApi.endpoints.getProjectNormalized.matchFulfilled, (state, action) => {
      const projectId = action.payload.result
      const prevProject = state[projectId]
      const project = action.payload.entities.projects?.[projectId]
      if (prevProject != null) {
        assignIfDeepDifferent(prevProject, project)
      } else {
        state[projectId] = castDraft(project)
      }
    })
  }),

  projectDescription: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withDescription) {
          Object.assign(state, action.payload.entities.projectDescription)
        }
      },
    )
  }),

  projectParameters: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withParameters) {
          Object.assign(state, action.payload.entities.projectParameters)
        }
      },
    )
  }),

  projectLinks: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withLinks) {
          Object.assign(state, action.payload.entities.projectLinks)
        }
      },
    )
  }),

  projectTemplates: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withTemplates) {
          Object.assign(state, action.payload.entities.projectTemplates)
        }
      },
    )
  }),

  overviewBuildTypes: createReducer<KeyValue<BuildTypeId, BuildType>>({}, builder => {
    builder.addMatcher(
      restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
      (state, action) => {
        Object.assign(state, action.payload.entities.overviewBuildTypes)
      },
    )
    builder.addMatcher(restApi.endpoints.getBuildTypeNormalized.matchFulfilled, (state, action) => {
      const buildTypeId = getIdFromLocator(action.meta.arg.originalArgs.btLocator)
      const buildType = action.payload.entities.buildTypes?.[buildTypeId]

      const oldBuildType = original(state)![buildTypeId]
      if (oldBuildType != null && !deepEqual(oldBuildType, buildType)) {
        state[buildTypeId] = castDraft(buildType)
      }
    })
  }),

  testOccurrences: createReducer<KeyValue<TestOccurrenceId, TestOccurrence>>({}, builder => {
    const mergeTestOccurrence = (
      state: Draft<KeyValue<TestOccurrenceId, TestOccurrence>>,
      testOccurrences: KeyValue<TestOccurrenceId, TestOccurrence> | undefined,
    ) => {
      if (testOccurrences != null) {
        for (const [testOccurrenceId, testOccurrence] of objectEntries(testOccurrences)) {
          const count = testOccurrence?.invocations?.testCounters?.all

          if (count == null || count === 0) {
            const prevState = state[testOccurrenceId]
            if (prevState != null) {
              assignIfDeepDifferent(prevState, testOccurrence)
            } else {
              state[testOccurrenceId] = testOccurrence
            }
          }
        }
      }
    }
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        const testOccurrences = action.payload.data.entities.testOccurrences

        mergeTestOccurrence(state, testOccurrences)
      },
    )

    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        const testOccurrences = action.payload.entities.testOccurrences

        mergeTestOccurrence(state, testOccurrences)
      },
    )
  }),

  multirunTestOccurrences: createReducer<KeyValue<TestOccurrenceId, TestOccurrence>>(
    {},
    builder => {
      const mergeMultirunTestOccurrences = (
        state: Draft<KeyValue<TestOccurrenceId, TestOccurrence>>,
        testOccurrences: KeyValue<TestOccurrenceId, TestOccurrence> | undefined,
      ) => {
        if (testOccurrences != null) {
          for (const [testOccurrenceId, testOccurrence] of objectEntries(testOccurrences)) {
            const count = testOccurrence?.invocations?.testCounters?.all

            if (count != null && count > 0) {
              const prevState = state[testOccurrenceId]
              if (prevState != null) {
                assignIfDeepDifferent(prevState, testOccurrence)
              } else {
                state[testOccurrenceId] = testOccurrence
              }
            }
          }
        }
      }

      builder.addMatcher(
        restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
        (state, action) => {
          const testOccurrences = action.payload.data.entities.testOccurrences

          mergeMultirunTestOccurrences(state, testOccurrences)
        },
      )

      builder.addMatcher(
        isAnyOf(
          restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
          restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
        ),
        (state, action) => {
          const testOccurrences = action.payload.entities.testOccurrences
          mergeMultirunTestOccurrences(state, testOccurrences)
        },
      )
    },
  ),

  testOccurrencesFirstFailed: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withFirstFailed) {
          Object.assign(state, action.payload.entities.testOccurrencesFirstFailed)
        }
      },
    )
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withFirstFailed) {
          Object.assign(state, action.payload.data.entities.testOccurrencesFirstFailed)
        }
      },
    )
  }),

  testOccurrencesNextFixed: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withNextFixed) {
          Object.assign(state, action.payload.entities.testOccurrencesNextFixed)
        }
      },
    )
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withNextFixed) {
          Object.assign(state, action.payload.data.entities.testOccurrencesNextFixed)
        }
      },
    )
  }),

  testOccurrencesRunOrder: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withRunOrder) {
          Object.assign(state, action.payload.entities.testOccurrencesRunOrder)
        }
      },
    )
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withRunOrder) {
          Object.assign(state, action.payload.data.entities.testOccurrencesRunOrder)
        }
      },
    )
  }),

  testOccurrencesNewFailure: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withNewFailure) {
          Object.assign(state, action.payload.entities.testOccurrencesNewFailure)
        }
      },
    )
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withNewFailure) {
          Object.assign(state, action.payload.data.entities.testOccurrencesNewFailure)
        }
      },
    )
  }),

  testOccurrencesMetadataCount: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withMetadataCount) {
          Object.assign(state, action.payload.entities.testOccurrencesMetadataCount)
        }
      },
    )
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withMetadataCount) {
          Object.assign(state, action.payload.data.entities.testOccurrencesMetadataCount)
        }
      },
    )
  }),

  testOccurrencesInvestigations: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withInvestigationInfo) {
          Object.assign(state, action.payload.entities.testOccurrencesInvestigations)
        }
      },
    )
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withInvestigationInfo) {
          Object.assign(state, action.payload.data.entities.testOccurrencesInvestigations)
        }
      },
    )
  }),

  testOccurrencesCurrentlyMutes: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withMuteInfo) {
          Object.assign(state, action.payload.entities.testOccurrencesCurrentlyMutes)
        }
      },
    )
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withMuteInfo) {
          Object.assign(state, action.payload.data.entities.testOccurrencesCurrentlyMutes)
        }
      },
    )
  }),

  testOccurrencesMute: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withMuteInfo) {
          Object.assign(state, action.payload.entities.testOccurrencesMute)
        }
      },
    )
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withMuteInfo) {
          Object.assign(state, action.payload.data.entities.testOccurrencesMute)
        }
      },
    )
  }),

  testOccurrencesInvocationsCounters: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withInvocationsCounters) {
          Object.assign(state, action.payload.entities.testOccurrencesInvocationsCounters)
        }
      },
    )
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withInvocationsCounters) {
          Object.assign(state, action.payload.data.entities.testOccurrencesInvocationsCounters)
        }
      },
    )
  }),

  problemOccurrences: createReducer<KeyValue<ProblemOccurrenceId, ProblemOccurrence>>(
    {},
    builder => {
      builder.addMatcher(
        restApi.endpoints.getBuildProblemOccurrence.matchFulfilled,
        (state, action) => {
          state[action.payload.id!] = action.payload
        },
      )
      builder.addMatcher(
        restApi.endpoints.getProblemOccurrenceTree.matchFulfilled,
        (state, action) => {
          Object.assign(state, action.payload.entities.problemOccurrences)
        },
      )
    },
  ),

  buildArtifacts: buildArtifacts.reducer,

  compatibleAgents: compatibleAgents.reducer,

  builds: createReducer<KeyValue<BuildId, NormalizedBuild | null>>({}, builder => {
    function mergeBuilds(
      state: Draft<KeyValue<BuildId, NormalizedBuild | null>>,
      builds: KeyValue<BuildId, NormalizedBuild | null> | undefined,
    ) {
      merge(state, builds)
      for (const build of Object.values(state)) {
        if (build?.state === 'finished') {
          build['running-info'] = undefined
        }
      }
    }

    const mergeBuildsFromEntities = (
      state: Draft<KeyValue<BuildId, NormalizedBuild | null>>,
      action: PayloadAction<Normalized<unknown>>,
    ) => mergeBuilds(state, action.payload.entities.builds)

    builder.addCase(receiveBuildWSDataAction, (state, action) => mergeBuilds(state, action.payload))

    builder.addCase(pinBuild.fulfilled, (state, action) => {
      const build = state[action.meta.arg.buildId]
      if (build != null) {
        build.pinned = action.payload
      }
    })

    builder.addCase(commentBuild.fulfilled, (state, action) => {
      const build = state[action.meta.arg.buildId]
      if (build != null) {
        build.comment ??= {}
        build.comment.text = action.payload
      }
    })

    builder.addCase(tagBuild.fulfilled, (state, action) => {
      const build = state[action.meta.arg.buildId]
      if (build != null) {
        build.tags = castDraft(action.payload)
      }
    })

    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withBuildInfo) {
          mergeBuilds(state, action.payload.entities.builds)
        }
      },
    )

    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withBuildInfo) {
          mergeBuilds(state, action.payload.data.entities.builds)
        }
      },
    )

    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllBranchesOfBuildTypeMerged.matchFulfilled,
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
        restApi.endpoints.getBuildNormalized.matchFulfilled,
        restApi.endpoints.getAllAgentsNormalized.matchFulfilled,
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled,
        receiveBuildAction,
        restApi.endpoints.setMultipleBuildComments.matchFulfilled,
        restApi.endpoints.addTagsToMultipleBuilds.matchFulfilled,
        restApi.endpoints.pinMultipleBuilds.matchFulfilled,
      ),
      mergeBuildsFromEntities,
    )

    builder.addMatcher(restApi.endpoints.getChangeLog.matchFulfilled, (state, action) =>
      mergeBuilds(state, action.payload.builds?.entities.builds),
    )

    builder.addMatcher(restApi.endpoints.setBuildTags.matchPending, (state, action) => {
      const build = state[toBuildId(getIdFromLocator(action.meta.arg.originalArgs.buildLocator))]
      if (build != null) {
        build.tags = castDraft(action.meta.arg.originalArgs.tags)
      }
    })

    builder.addMatcher(restApi.endpoints.setBuildTags.matchFulfilled, (state, action) => {
      const build = state[toBuildId(getIdFromLocator(action.meta.arg.originalArgs.buildLocator))]
      if (build != null) {
        build.tags = castDraft(action.payload)
      }
    })

    builder.addMatcher(restApi.endpoints.setBuildTags.matchRejected, (state, action) => {
      const build = state[toBuildId(getIdFromLocator(action.meta.arg.originalArgs.buildLocator))]
      if (build != null) {
        build.tags = castDraft(action.meta.arg.originalArgs.prevTags)
      }
    })
  }),

  buildsTestOccurrencesCount: createReducer<KeyValue<BuildId, number | undefined>>({}, builder => {
    builder.addCase(receiveBuildWSDataAction, (state, action) => {
      for (const [id, build] of Object.entries(action.payload)) {
        const buildId = toBuildId(id)
        const count = build?.testOccurrences?.count
        if (count != null) {
          state[buildId] = count
        } else {
          delete state[buildId]
        }
      }
    })
  }),

  changes: createReducer<KeyValue<ChangeId, NormalizedChange>>({}, builder => {
    builder.addMatcher(
      restApi.endpoints.getModificationsOfChange.matchFulfilled,
      (state, action) => {
        Object.assign(state, action.payload.entities.changes)
      },
    )
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllChanges.matchFulfilled,
        restApi.endpoints.getChangeLog.matchFulfilled,
      ),
      (state, action) => {
        Object.assign(state, action.payload.changes?.entities.changes)
      },
    )
    builder.addMatcher(
      isAnyOf(receiveBuildChanges, restApi.endpoints.getBuildNormalized.matchFulfilled),
      (state, action) => {
        const {changes} = action.payload.entities
        if (changes != null) {
          objectEntries(changes).forEach(([key, value]) => {
            if (!(key in state)) {
              state[key] = castDraft(value)
            }
          })
        }
      },
    )
  }),

  modificationsOfChanges: createReducer(getEmptyHash(), builder => {
    builder.addMatcher(
      restApi.endpoints.getModificationsOfChange.matchFulfilled,
      (state, action) => {
        Object.assign(state, action.payload.entities.modificationsOfChanges)
      },
    )
  }),

  revisions: createReducer({}, builder => {
    builder.addMatcher(restApi.endpoints.getAllBuildsNormalized.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.revisions)
    })
  }),

  vcsLabels: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        receiveBuildChanges.match,
        restApi.endpoints.getBuildNormalized.matchFulfilled,
        restApi.endpoints.getModificationsOfChange.matchFulfilled,
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
      ),
      (state, action) => {
        Object.assign(state, action.payload.entities.vcsLabels)
      },
    )
  }),

  vcsRootInstances: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllChanges.matchFulfilled,
        restApi.endpoints.getChangeLog.matchFulfilled,
      ),
      (state, action) => {
        Object.assign(state, action.payload.changes?.entities.vcsRootInstances)
      },
    )
    builder.addMatcher(
      isAnyOf(
        receiveBuildChanges.match,
        restApi.endpoints.getBuildNormalized.matchFulfilled,
        restApi.endpoints.getModificationsOfChange.matchFulfilled,
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
      ),
      (state, action) => {
        Object.assign(state, action.payload.entities.vcsRootInstances)
      },
    )
  }),

  vcsRoots: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllChanges.matchFulfilled,
        restApi.endpoints.getChangeLog.matchFulfilled,
      ),
      (state, action) => {
        Object.assign(state, action.payload.changes?.entities.vcsRoots)
      },
    )
    builder.addMatcher(
      isAnyOf(
        receiveBuildChanges.match,
        restApi.endpoints.getBuildNormalized.matchFulfilled,
        restApi.endpoints.getModificationsOfChange.matchFulfilled,
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
      ),
      (state, action) => {
        Object.assign(state, action.payload.entities.vcsRoots)
      },
    )
  }),

  snapshotDependencies: createReducer<SnapshotDependenciesIDList>({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllAgentsNormalized.matchFulfilled,
        restApi.endpoints.getAllBranchesOfBuildTypeMerged.matchFulfilled,
        restApi.endpoints.getBuildNormalized.matchFulfilled,
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled,
        receiveBuildAction.match,
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
      ),
      (state, action) => {
        const builds = action.payload.entities.builds

        if (!builds) {
          return
        }

        for (const [buildId, build] of objectEntries(builds)) {
          const dependencies = build?.['snapshot-dependencies']?.build

          if (dependencies != null) {
            state[toBuildId(buildId!)] = dependencies.map(dependency => dependency.id!)
          }
        }
      },
    )
  }),
})

import memoize from '@jetbrains/ring-ui/components/global/memoize'
import type {CustomAnchorProps, SelectItem} from '@jetbrains/ring-ui/components/select/select'
import type {TagType} from '@jetbrains/ring-ui/components/tags-list/tags-list'
import type * as React from 'react'
import type {$Values} from 'utility-types'

import type {BuildApproval} from '../components/common/BuildApproval/BuildApproval.types'

import type {NormalizedProjects} from '../rest/schemata'
import type {
  Agent as RestAgent,
  AgentPool,
  Branch,
  Build as RestBuild,
  BuildType,
  CloudImage,
  DownloadInfo,
  EnabledInfo,
  Links,
  Properties,
  Tags,
  User as RestUser,
} from '../services/rest'
import type {KeyValue, WritableKeyValue} from '../utils/object'

// can't use ElementType, see https://github.com/DefinitelyTyped/DefinitelyTyped/discussions/53864
export type HOC<I, O> = (component: React.ComponentType<I>) => React.ComponentType<O>

export type Id = number | string
export type BuildId = number
export type BuildTypeId = string
export type ProjectId = string
export type CustomSidebarItemId = string
export type BuildTypeInternalId = string
export type ProjectInternalId = string
export type UserId = number
export type AgentId = number
export type AgentPoolId = number
export type AgentTypeId = number
type CloudProfileId = string
export type CloudInstanceId = string
export type ChangeId = number
export type TestId = string
export type TestNameId = string
export type TestOccurrenceId = string
export type ProblemId = string
export type ProblemOccurrenceId = string
type SystemProblemId = string
type InvestigationId = string
export type FederationServerId = string
export type TabId = string
export type RevisionId = string
export type UniqueRevisionId = string // `${vcsRootInstanceId}_${revisionId}`
export type VcsRootInstanceId = string
export type VcsRootId = string
export type TestsTreeNodeId = string
export type ProblemsTreeNodeId = string
export const stringifyId = (id: (Id | null | undefined) | string): string =>
  id != null ? String(id) : ''
export const toBuildId = (key: string | number): BuildId => Number(key)
export const toChangeId = (key: string | number): ChangeId => Number(key)
export const toBuildTypeId = (key: string): BuildTypeId => key
export const toBuildTypeInternalId = (key: string): BuildTypeInternalId => key
export const toProjectId = (key: string): ProjectId => key
export const toProjectInternalId = (key: string): ProjectInternalId => key
export const toUserId = (key: string | number): UserId => Number(key)
export const toAgentId = (key: string | number): AgentId => Number(key)
export const toAgentTypeId = (key: string | number): AgentTypeId => Number(key)
export const toAgentPoolId = (key: string | number): AgentPoolId => Number(key)
export const toTestsTreeNodeId = (key: string | number): TestsTreeNodeId => String(key)
export const toFederationServerId = (key: string): FederationServerId => key
export const toCustomSidebarItemId = (key: string): CustomSidebarItemId => key
export const toTabId = (key: string): TabId => String(key)
export const toProblemId = (key: string): ProblemId => String(key)
export const toProblemOccurrenceId = (key: string): ProblemOccurrenceId => String(key)
export const toSystemProblemId = (key: string): SystemProblemId => String(key)
export const toInvestigationId = (key: string): InvestigationId => String(key)
export const toTestId = (key: string | number): TestId => String(key)
export const toTestOccurrenceId = (key: string): TestOccurrenceId => String(key)
export const toRevisionId = (key: string): RevisionId => String(key)
export const toVcsRootInstanceId = (key: string | number): VcsRootInstanceId => String(key)
export const toVcsRootId = (key: string): VcsRootId => String(key)
export const ROOT_PROJECT_ID: ProjectId = '_Root'
export const ROOT_PROJECT_INTERNAL_ID: ProjectInternalId = '_Root'
export const ALL_PROJECTS: ProjectId = Symbol() as any
export type NodeType = 'project' | 'bt' | 'build'
export type NodeGroup = 'favorites' | 'all' | 'search' | 'none'
export type LinkType = 'webView' | 'webEdit' | 'webViewSettings'
export type Inexact<T> = T & {
  [K in PropertyKey]: unknown
}
type WebEntity = {
  description?: string
  name?: string
  webUrl?: string
}
type InexactWebEntity = Inexact<WebEntity>
export type WebLinks = {
  links?: Links
}
type WebEntityWithLinks = WebEntity & WebLinks
type InexactWebEntityWithLinks = Inexact<WebEntityWithLinks>
export type TypeOfBuildType = 'composite' | 'deployment' | 'regular'
type EntityParameters = {
  parameters?: Properties
  pauseComment?: CommentInfo
}
export type InexactEntityParameters = Inexact<EntityParameters>
export type EntityDescription = {
  description?: string | undefined
}
export type EntityProjectsIdsList = ReadonlyArray<{
  id: ProjectId
}>
export type EntityArchivedSubprojectsIds = {
  projects?: {
    project: EntityProjectsIdsList
  }
}
type BuildTypeCollection = {
  readonly buildType: BuildType[]
}
type BuildTypeWithDetailsCollection = {
  readonly buildType: BuildType[]
}
export type Template = {
  id: string
  name: string
}
type BaseProject<P> = WebEntity & {
  id: ProjectId
  internalId: ProjectInternalId
  parentProjectId?: ProjectId
  virtual?: boolean
  archived: boolean
  readOnlyUI?: {
    value: boolean
  }
  templates?: {
    buildType: Template[]
  }
  ancestorProjects?: {
    project: P[]
  }
}
export type Project = BaseProject<BaseProject<never>> & {
  readonly buildTypes?: BuildTypeCollection
}
export type ProjectWithDetails = BaseProject<BaseProject<never>> &
  WebLinks &
  EntityParameters & {
    buildTypes?: BuildTypeWithDetailsCollection
  }
export type NormalizedProject = BaseProject<ProjectId> & {
  buildTypes?: {
    buildType: Array<BuildTypeId>
  }
  projects?: {
    project:
      | Array<{
          id: ProjectId
        }>
      | undefined
  }
}
export type SelectedBranch = Branch & {wildcard?: boolean}
export type BranchItem = SelectItem<{
  label?: string
  selectedLabel?: React.ReactNode
  key: string
  branch?: SelectedBranch
}>
export type OSType =
  | 'Windows'
  | 'macOS'
  | 'Linux'
  | 'Solaris'
  | 'FreeBSD'
  | 'Unix'
  | 'Unknown'
  | '%future added value'
export type AvatarSize = `urlToSize${number}`
export const getAvatarSizeKey = (size: number): AvatarSize => `urlToSize${size}`
type Avatars = KeyValue<string, string>
export type User = {
  id?: UserId
  name?: string | undefined
  username: string
  avatars?: Avatars
  email?: string
}
export type Group = {
  key: string
  name: string
  description?: string
  users?: Countable
}
export type CommentInfo = {
  text?: string
  timestamp?: string
  user?: RestUser
}
export type AgentPoolsHash = KeyValue<AgentPoolId, AgentPool>
type CloudProfile = {
  id: CloudProfileId
  name?: string
  projectId?: ProjectId
}
type BaseCloudImage = {
  name: string
  agentPoolId: AgentPoolId
  agentTypeId: AgentTypeId
  profile?: CloudProfile
}
type CloudInstance = {
  id?: 'id:fake0,imageId:jb-hosted,profileId:tc-1'
  image?: CloudImage
  agent?: {
    id: AgentId
    typeId?: AgentTypeId
    pool?: AgentPool
  }
}
export type NormalizedCloudImage = BaseCloudImage &
  Environment & {
    agentIds: Array<AgentId>
  }
export type CloudImagesHash = KeyValue<AgentTypeId, NormalizedCloudImage>
type Environment = {
  osType?: OSType | undefined
  osName?: string | undefined
}
type BaseAgentPreview<B> = {
  id: AgentId
  typeId: AgentTypeId
  name: string
  ip?: string
  enabled?: boolean
  connected: boolean
  authorized?: boolean
  pool: AgentPool
  build?: B | undefined
}
export type NormalizedAgentPreview = BaseAgentPreview<BuildId> & {
  osType: OSType | undefined
}
export type WritableAgentPreviewsHash = WritableKeyValue<
  AgentId,
  NormalizedAgentPreview | null | undefined
>
export type AgentPreviewsHash = Readonly<WritableAgentPreviewsHash>
type BaseAgent<B> = WebEntityWithLinks &
  BaseAgentPreview<B> & {
    cloudInstanceId?: CloudInstanceId
    enabledInfo?: EnabledInfo
    authorizedInfo?: EnabledInfo
    uptodate?: boolean
    version?: string
    outdated?: boolean
    currentAgentVersion?: string
    pluginsOutdated?: boolean
    javaOutdated?: boolean
    lastActivityTime?: string
    disconnectionComment?: string
    deleted?: boolean
  }
export type AgentDetails = Pick<RestAgent, 'host' | 'port' | 'protocol' | 'cpuRank'> & {
  connectedSince?: string | undefined
}
export type NormalizedAgent = BaseAgent<BuildId> & Environment
export type AgentRequestOptions = {
  authorizedInfo?: boolean
  disconnectionComment?: boolean
  lastActivityTime?: boolean
  enabledInfo?: boolean
  cloudInfo?: boolean
  details?: boolean
  tabs?: boolean
}
type ArchivedOption = 'any' | 'false' | 'true'
export type RequestOptionsParams = {
  withLinks?: boolean
  withVirtual?: boolean
  withBuildTypes?: boolean
  withTemplates?: boolean
  archived?: ArchivedOption
  withLowNesting?: boolean
  withArchivedSubprojectsIds?: boolean
  federationServerId?: FederationServerId
  withParameters?: boolean
  withDescription?: boolean
  withQueuedInfo?: boolean
  withRunningInfo?: boolean
  withShortProjectDetails?: boolean
  withBuildTypeDetails?: boolean
  withSnapshotDependencies?: boolean
  withDownloadedArtifactsFrom?: BuildId | null
  withPauseComment?: boolean
  withProgress?: boolean
  withAncestorProjects?: boolean
  fetchCount?: number
  includeRoot?: boolean
  customEndpoint?: string
  essential?: boolean
  cache?: boolean
  projectReceiveMeta?: ProjectReceiveMeta
}
export type ProjectReceiveMeta = {
  sidebarProjectsLoaded?: boolean
}
type ProblemOccurrencesTreeReceiveMeta = {
  depth?: number
}
type TestOccurrencesTreeReceiveMeta = {
  depth?: number
}
export type SuccessReceiveMeta = ProjectReceiveMeta &
  ProblemOccurrencesTreeReceiveMeta &
  TestOccurrencesTreeReceiveMeta & {}

export type RunningInfo = {
  percentageComplete?: number
  elapsedSeconds: number
  estimatedTotalSeconds?: number
  leftSeconds?: number
  lastActivityTime?: string
  probablyHanging?: boolean
  outdated?: boolean
  outdatedReasonBuild?: InexactWebEntityWithLinks & {
    number: string | undefined
  }
}
export type Countable = {
  count?: number
}
export type InexactCountable = Inexact<Countable>
export type CompatibleAgent = {
  id: BuildId
  compatibleAgents?: InexactCountable
  compatibleCloudImages?: InexactCountable
}
export type Tag = {
  name: string
  private: boolean
  key?: string
  value?: string
}
export type BuildStatus =
  | 'UNKNOWN'
  | 'SUCCESS' // NORMAL
  | 'WARNING'
  | 'FAILURE'
  | 'ERROR'
export type BuildState = 'queued' | 'running' | 'finished' | 'deleted' | 'unknown'
export type BuildArtifacts = {
  id: BuildId
  artifacts?: InexactCountable
}
type BuildTriggeredBuild = InexactWebEntityWithLinks & {
  id: BuildId | undefined
  number?: string | undefined
}
type BaseBuildTriggered<BT> = {
  date?: string
  displayText?: string | undefined
  build?: BuildTriggeredBuild
  buildType?: BT | undefined
  user?: RestUser
}
export type BuildTriggered = BaseBuildTriggered<BuildType>
export type NormalizedBuildTriggered = BaseBuildTriggered<BuildTypeId>
export type BuildArtifactsSize = {
  total: string
  visible: string
}
type FileChangeType = 'edited' | 'added' | 'removed'
export type ChangeFile = {
  changeType?: FileChangeType
  file?: string
  directory?: boolean
}
type ChangeStatus = {
  newFailedTests?: number
  otherFailedTests?: number
  totalProblems?: number
  cancelledBuilds?: number
  finishedBuilds?: number
  failedBuilds?: number
  runningBuilds?: number
  queuedBuildsCount?: number
  pendingBuildTypes?: number
  runningSuccessfullyBuilds?: number
  successfulBuilds?: number
  compilationErrorBuilds?: {
    count?: number
  }
  criticalBuilds?: {
    count?: number
    build?: Array<Build>
  }
  newTestsFailedBuilds?: {
    count?: number
    build?: Array<Build>
  }
  notCriticalBuilds?: {
    count?: number
    build?: Array<Build>
  }
}
type ChangeBranch = {
  name: string
  default?: boolean
}
export type ChangeFiles =
  | {
      count?: number
      file?: Array<ChangeFile>
    }
  | undefined

export type BaseChange<V, C = BaseChange<V, {}>> = {
  id: ChangeId
  type?: 'SNAPSHOT_DEPENDENCY_VCS_CHANGE' | 'VCS_CHANGE'
  username?: string
  commiter?: {
    users: {
      user: User[]
    }
  }
  version?: RevisionId
  parentRevisions?: {
    item?: Array<RevisionId>
  }
  mergedInfo?: {
    changes?: {
      change?: Array<C>
    }
    branches?: {
      branch?: Array<ChangeBranch>
    }
  }
  personal?: boolean
  comment?: string
  status?: ChangeStatus
  date?: string
  webUrl?: string
  isArtifactChange?: boolean
  vcsRootInstance?: V | undefined
  snapshotDependencyLink?: {
    build?: Build
    buildType?: BuildType
    buildTypeBranch?: string
  }
  storesProjectSettings?: boolean
  files?: ChangeFiles
}
export type NormalizedChange = BaseChange<VcsRootInstanceId, ChangeId>
export type VcsRoot = {
  id?: VcsRootId
  vcsName?: string
  name?: string
  project?: Project
}
type BaseVcsRootInstance<V> = {
  id: VcsRootInstanceId
  'vcs-root'?: V
}
export type NormalizedVcsRootInstance = BaseVcsRootInstance<VcsRootId>
type VcsRootInstance = BaseVcsRootInstance<VcsRoot>

type VcsLabelStatus = 'SUCCESSFUL_SET' | 'FAILED'
export type VcsLabel = {
  text?: string
  failureReason?: string
  status?: VcsLabelStatus
  'vcs-root-instance'?: {
    'vcs-root': {
      id: VcsRootId
    }
  }
}
export type Change = BaseChange<VcsRootInstance>
export const getUniqueRevisionId = (
  vcsRootInstanceId: VcsRootInstanceId,
  revisionId: RevisionId,
): UniqueRevisionId => `${stringifyId(vcsRootInstanceId)}_${stringifyId(revisionId)}`
type BaseRevision<V> = {
  version: RevisionId
  vcsBranchName: string
  'vcs-root-instance': V
}
export type NormalizedRevision = BaseRevision<VcsRootInstanceId>
export type Revision = BaseRevision<VcsRootInstance>
type Changes<C> = InexactCountable & {
  change?: Array<C>
}
type BaseBuildChanges<C, B> = {
  id: BuildId
  changes?: Changes<C>
  artifactDependencyChanges?: InexactCountable & {
    buildChange?: Array<{
      nextBuild?: B
    }>
  }
}
export type BuildChangesRevisions = {
  id: BuildId
  versionedSettingsRevision?: Revision | undefined
  revisions:
    | {
        revision: Array<Revision> | undefined
      }
    | undefined
  vcsLabels: VcsLabel[]
}
type DownloadedArtifacts<T extends DownloadInfo = DownloadInfo> = {
  downloadedArtifacts: {
    downloadInfo: T[]
  }
}
type BaseMatrixDependency<B> = {
  build?: B
  parameters?: {
    entry?: {
      name?: string
      value?: string
    }[]
  }
}
export type NormalizedMatrixDependency = BaseMatrixDependency<BuildId>
export type MatrixParameter = {
  name?: string
  values?: string[]
}
type BaseMatrixConfiguration<B> = {
  enabled?: boolean
  dependencies?: {
    dependency?: BaseMatrixDependency<B>[]
  }
  parameters?: {
    parameter?: MatrixParameter[]
  }
}
type BaseBuild<BT, C, R, A, B> = InexactWebEntity &
  WebLinks &
  BaseBuildChanges<C, B> &
  BuildArtifacts &
  CompatibleAgent &
  Partial<DownloadedArtifacts> & {
    id: BuildId
    agent?: A
    plannedAgent?: A
    comment?: CommentInfo
    statusChangeComment?: CommentInfo | undefined
    branchName?: string
    defaultBranch?: boolean
    buildType: BT
    queuedDate?: string
    startDate?: string
    finishDate?: string
    number?: string
    status?: BuildStatus
    detachedFromAgent?: boolean
    finishOnAgentDate?: string
    state?: BuildState
    canceledInfo?: CommentInfo | undefined
    failedToStart?: boolean
    statusText?: string
    personal?: boolean
    waitReason?: string
    startEstimate?: string
    finishEstimate?: string
    user?: User
    approvalInfo?: Pick<BuildApproval, 'status'>
    'running-info'?: RunningInfo | undefined
    tags?: Tags
    pinned?: boolean
    pinInfo?: CommentInfo | undefined
    'artifact-dependencies'?: InexactCountable & {
      build?: Array<{
        id: BuildId
      }>
    }
    'snapshot-dependencies'?: InexactCountable & {
      build?: Array<RestBuild>
    }
    versionedSettingsRevision?: R | undefined
    revisions?:
      | {
          revision: Array<R> | undefined
        }
      | undefined
    queuePosition?: number
    triggered?: BaseBuildTriggered<BT>
    history?: boolean
    limitedChangesCount?: number
    composite?: boolean
    parallelized?: boolean
    customization?: {
      changes?: {}
      parameters?: {}
      artifactDependencies?: {}
    }
    testOccurrences?: Countable
    delayedByBuild?: B
    related?: {
      builds?: {
        build?: B[]
      }
    }
    firstBuildWithSameChanges?: B
    matrixConfiguration?: BaseMatrixConfiguration<B>
  }

export type NormalizedBuild = BaseBuild<BuildTypeId, ChangeId, RevisionId, NormalizedAgent, BuildId>
type BuildAgent = WebEntityWithLinks & {
  name: string
  id?: AgentId
  environment: Environment
  typeId: AgentTypeId
  connected?: boolean
  pool?: AgentPool
}

type RelatedBuild = BaseBuild<BuildType, never, never, never, never>
export type Build = BaseBuild<BuildType, Change, Revision, BuildAgent, RelatedBuild>
export type AgentPreview = BaseAgentPreview<{
  id: BuildId
}> & {
  environment: Environment
}
export type Agent = BaseAgent<Build> &
  AgentDetails & {
    environment: Environment
  }
export type AgentResponse = Agent & {
  cloudInstance?: CloudInstance
}
export enum Permission {
  RUN_BUILD = 'run_build',
  CANCEL_BUILD = 'cancel_build',
  CANCEL_ANY_PERSONAL_BUILD = 'cancel_any_personal_build',
  TAG_BUILD = 'tag_build',
  CHANGE_OWN_PROFILE = 'change_own_profile',
  PIN_UNPIN_BUILD = 'pin_unpin_build',
  AUTHORIZE_AGENT = 'authorize_agent',
  REORDER_BUILD_QUEUE = 'reorder_build_queue',
  COMMENT_BUILD = 'comment_build',
  ASSIGN_INVESTIGATION = 'assign_investigation',
  CHANGE_SERVER_SETTINGS = 'change_server_settings',
  VIEW_BUILD_CONFIGURATION_SETTINGS = 'view_build_configuration_settings',
  VIEW_BUILD_RUNTIME_DATA = 'view_build_runtime_data',
  MANAGE_SERVER_LICENSES = 'manage_server_licenses',
  MANAGE_BUILD_PROBLEMS = 'manage_build_problems',
  EDIT_PROJECT = 'edit_project',
  EDIT_VCS_MODIFICATION = 'edit_vcs_modification',
  CREATE_SUB_PROJECT = 'create_sub_project',
  VIEW_USER_PROFILE = 'view_user_profile',
  MANAGE_AGENT_POOLS = 'manage_agent_pools',
  PAUSE_ACTIVATE_BUILD_CONFIGURATION = 'pause_activate_build_configuration',
  CHANGE_CLEANUP_RULES = 'change_cleanup_rules',
  REMOVE_BUILD = 'remove_build',
  CHANGE_USER = 'change_user',
}

export type ChangesRequestOptions = {
  withUpdatePager?: boolean
  withFiles?: boolean
  withStatus?: boolean
}

export type PoolPermissions = KeyValue<AgentPoolId, boolean>
export type Fetchable<T> = {
  inited: boolean
  loading: boolean
  backgroundLoading: boolean
  ready: boolean
  error?: Error | null | undefined
  data: T
  request?: Promise<unknown> | null | undefined
  receiveMeta?: SuccessReceiveMeta
}
export type FederationServerData = {
  authorized: boolean
  projects?: NormalizedProjects
}
export type ProjectPathItem = NormalizedProject & WebLinks
export type PathItem = ProjectPathItem | BuildType
export type FullPath = ReadonlyArray<PathItem>
export type CurrentUser = User & {
  properties?: Properties
}
type ProjectStatusRequest = {
  id?: ProjectId
  type: 'project'
  branch?: Branch | null | undefined
}
type BuildTypeStatusRequest = {
  id?: BuildTypeId
  type: 'bt'
  branch?: Branch | null | undefined
}
export type StatusRequest = ProjectStatusRequest | BuildTypeStatusRequest
export const getProjectStatusRequest: (
  projectId?: ProjectId,
  branch?: Branch | null,
) => StatusRequest = (id, branch) => ({
  type: 'project',
  id,
  branch,
})
export const getBuildTypeStatusRequest: (
  buildTypeId?: BuildTypeId,
  branch?: Branch | null,
) => StatusRequest = (id, branch) => ({
  type: 'bt',
  id,
  branch,
})

export function getStatusRequest(
  type: 'project',
  id: ProjectId,
  branch?: Branch | null,
): StatusRequest
export function getStatusRequest(
  type: 'bt',
  id?: BuildTypeId,
  branch?: Branch | null,
): StatusRequest
export function getStatusRequest(
  type: 'project' | 'bt',
  id?: ProjectId | BuildTypeId,
  branch?: Branch | null,
) {
  return type === 'project'
    ? getProjectStatusRequest(id, branch)
    : getBuildTypeStatusRequest(id, branch)
}

export type SystemProblem = {
  id: SystemProblemId
  type?: string | undefined
  title?: string
  description?: string | undefined
  problemDetails?: string | undefined
  webSpecifics?:
    | {
        solutionLink: {
          link: string
          title: string | undefined
        }
        caption: string
        additionalData: string | undefined
      }
    | undefined
}
type FailedCounts = {
  inLatestFinished?: number
  inRunning?: Array<number>
}
export type ProjectOrBuildTypeStatusBase = {
  status: 'SUCCESS' | 'FAILURE' | 'UNKNOWN'
  pending?: number
  pendingLimitExceeded?: boolean | undefined
  running?: number
  failing?: number
  queued?: number
  systemProblems?: Array<SystemProblem>
  additionalProblems?: Array<SystemProblem>
  investigation?: 'TAKEN' | 'FIXED' | 'GIVEN_UP' | 'NONE'
  failedCounts?: FailedCounts
}
export type ProjectOrBuildTypeStatus =
  | (ProjectStatusRequest & ProjectOrBuildTypeStatusBase)
  | (BuildTypeStatusRequest & ProjectOrBuildTypeStatusBase)
export type ExpandState = {
  type: 'project'
  id?: ProjectId
  expandState: 'EXPANDED' | 'COLLAPSED'
}
export type Sorting = {
  dimension: string
  descending: boolean
}
export type PinHandlerArgs = {
  buildId: BuildId
  isPinned: boolean
  hasDependencies: boolean
  pinComment: string | null | undefined
  tagsContainerId: string
  buildTypeId?: BuildTypeId
}
export type PinHandlerArgsAjax = {
  pin: boolean
  pinComment: string | undefined
  tags: ReadonlyArray<Tag>
  applyToChainBuilds: boolean
}
export type TagsHandlerArgs = {
  buildId: BuildId
  joinedTags: string
  escapedPrivateTags?: string
  hasDependencies: boolean
  tagsContainerId: string
  buildTypeId?: BuildTypeId
}
export type CommentHandlerArgs = {
  buildId: BuildId
  comment: string | null | undefined
}
export type TitleId = '_all_projects_title_' | '_favorite_projects_title_'
export const FAVORITES_TITLE_ID: TitleId = '_favorite_projects_title_'
export const ALL_PROJECTS_TITLE_ID: TitleId = '_all_projects_title_'
export type File = {
  name: string
  size?: number | undefined
}
export type UrlExtension<Options extends {}> = {
  name: string
  kind?: string
  serverUrl?: string
  endpoint: string
  options?: Options
}
export type RouteAvailabilityResponse = {
  status: string
  statusCode: number
  message: string
}
type TabTitlesEnum = KeyValue<TabId, string>

export const ClassicUIProjectTabNamesEnum = {
  projectOverview: toTabId('projectOverview') as TabId,
  projectChangeLog: toTabId('projectChangeLog') as TabId,
  stats: toTabId('stats') as TabId,
  problems: toTabId('problems') as TabId,
  investigations: toTabId('investigations') as TabId,
  mutedProblems: toTabId('mutedProblems') as TabId,
  projectBuildChains: toTabId('projectBuildChains') as TabId,
  flakyTests: toTabId('flakyTests') as TabId,
}
export const ClassicUIBuildTypeTabNamesEnum = {
  buildTypeBranches: toTabId('buildTypeBranches') as TabId,
  buildTypeStatusDiv: toTabId('buildTypeStatusDiv') as TabId,
  buildTypeHistoryList: toTabId('buildTypeHistoryList') as TabId,
  buildTypeChangeLog: toTabId('buildTypeChangeLog') as TabId,
  buildTypeIssueLog: toTabId('buildTypeIssueLog') as TabId,
  buildTypeStatistics: toTabId('buildTypeStatistics') as TabId,
  compatibilityList: toTabId('compatibilityList') as TabId,
  pendingChangesDiv: toTabId('pendingChangesDiv') as TabId,
  buildTypeChains: toTabId('buildTypeChains') as TabId,
  buildTypeSettings: toTabId('buildTypeSettings') as TabId,
}
export const ClassicUIBuildTabNamesEnum = {
  queuedBuildOverviewTab: toTabId('queuedBuildOverviewTab') as TabId,
  queuedBuildCompatibilityTab: toTabId('queuedBuildCompatibilityTab') as TabId,
  buildResultsDiv: toTabId('buildResultsDiv') as TabId,
  testsInfo: toTabId('testsInfo') as TabId,
  buildChangesDiv: toTabId('buildChangesDiv') as TabId,
  dependencies: toTabId('dependencies') as TabId,
  buildParameters: toTabId('buildParameters') as TabId,
  buildIssues: toTabId('buildIssues') as TabId,
  artifacts: toTabId('artifacts') as TabId,
  perfmon: toTabId('perfmon') as TabId,
  buildLog: toTabId('buildLog') as TabId,
  buildUsedResources: toTabId('buildUsedResources') as TabId,
  dockerBuildInfo: toTabId('dockerBuildInfo') as TabId,
}

export const ProjectPageTabNamesEnum = {
  ...ClassicUIProjectTabNamesEnum,
  CHANGE_LOG: toTabId('changeLog') as TabId,
  OVERVIEW: toTabId('overview') as TabId,
  STATISTICS: toTabId('stats') as TabId,
  TEST_DETAILS: toTabId('testDetails') as TabId,
  INVESTIGATIONS: toTabId('investigations') as TabId,
}
export const BuildTypePageTabNamesEnum = {
  ...ClassicUIBuildTypeTabNamesEnum,
  CHANGE_LOG: toTabId('changeLog') as TabId,
  OVERVIEW: toTabId('overview') as TabId,
  STATISTICS: toTabId('buildTypeStatistics') as TabId,
  PENDING_CHANGES: toTabId('pendingChanges') as TabId,
  CHAINS: toTabId('chains') as TabId,
}
export const BuildPageTabNamesEnum = {
  ...ClassicUIBuildTabNamesEnum,
  ARTIFACTS: toTabId('artifacts') as TabId,
  OVERVIEW: toTabId('overview') as TabId,
  TESTS: toTabId('tests') as TabId,
  CHANGES: toTabId('changes') as TabId,
  LOG: toTabId('log') as TabId,
  DEPENDENCIES: toTabId('dependencies') as TabId,
  PARAMETERS: toTabId('buildParameters') as TabId,
}
export const OverviewAgentTabNamesEnum = {
  SUMMARY: toTabId('summary') as TabId,
  HISTORY: toTabId('history') as TabId,
  COMPATIBLE_CONFIGUTATIONS: toTabId('compatible-configurations') as TabId,
  BUILD_RUNNERS: toTabId('build-runners') as TabId,
  LOGS: toTabId('logs') as TabId,
  PARAMETERS: toTabId('parameters') as TabId,
}
export const OverviewAgentsTabNamesEnum = {
  ALL_AGENTS: toTabId('all-agents') as TabId,
  PARAMETERS_REPORT: toTabId('agentsParametersReport') as TabId,
  AGENTS_STATISTICS: toTabId('agentsStatisticsTab') as TabId,
  AGENT_PUSH: toTabId('agent.push') as TabId,
}
export const OverviewCloudImageTabNamesEnum = {
  SUMMARY: toTabId('summary') as TabId,
  HISTORY: toTabId('history') as TabId,
  COMPATIBLE_CONFIGUTATIONS: toTabId('compatible-configurations') as TabId,
  BUILD_RUNNERS: toTabId('build-runners') as TabId,
  AGENT_PARAMETERS: toTabId('agent-parameters') as TabId,
}
export const OverviewAgentPoolTabNamesEnum = {
  AGENTS: toTabId('agents') as TabId,
  PROJECTS: toTabId('projects') as TabId,
  CLOUD_IMAGES: toTabId('cloud-images') as TabId,
}

export const ProjectPageTabTitlesEnum: TabTitlesEnum = {
  [ProjectPageTabNamesEnum.OVERVIEW]: 'Overview',
  [ProjectPageTabNamesEnum.CHANGE_LOG]: 'Change Log',
}
export const BuildTypePageTabTitlesEnum: TabTitlesEnum = {
  [BuildTypePageTabNamesEnum.OVERVIEW]: 'Overview',
  [BuildTypePageTabNamesEnum.PENDING_CHANGES]: 'Pending Changes',
  [BuildTypePageTabNamesEnum.CHAINS]: 'Chains',
  [BuildTypePageTabNamesEnum.CHANGE_LOG]: 'Change Log',
}
export const BuildPageTabTitlesEnum: TabTitlesEnum = {
  [BuildPageTabNamesEnum.ARTIFACTS]: 'Artifacts',
  [BuildPageTabNamesEnum.OVERVIEW]: 'Overview',
  [BuildPageTabNamesEnum.TESTS]: 'Tests',
  [BuildPageTabNamesEnum.CHANGES]: 'Changes',
  [BuildPageTabNamesEnum.LOG]: 'Build Log',
  [BuildPageTabNamesEnum.DEPENDENCIES]: 'Dependencies',
}

export const ClassicUIAgentTabNamesEnum: KeyValue<TabId, TabId> = {
  agentSummary: OverviewAgentTabNamesEnum.SUMMARY,
  agentHistory: OverviewAgentTabNamesEnum.HISTORY,
  agentCompatibleConfigurations: OverviewAgentTabNamesEnum.COMPATIBLE_CONFIGUTATIONS,
  agentBuildRunners: OverviewAgentTabNamesEnum.BUILD_RUNNERS,
  agentLogs: OverviewAgentTabNamesEnum.LOGS,
  agentParameters: OverviewAgentTabNamesEnum.PARAMETERS,
}
export const ClassicUICloudImageTabNamesEnum: KeyValue<string, TabId> = {
  agentTypeSummary: OverviewCloudImageTabNamesEnum.SUMMARY,
  agentHistory: OverviewCloudImageTabNamesEnum.HISTORY,
  agentCompatibleConfigurations: OverviewCloudImageTabNamesEnum.COMPATIBLE_CONFIGUTATIONS,
  agentBuildRunners: OverviewCloudImageTabNamesEnum.BUILD_RUNNERS,
  agentParameters: OverviewCloudImageTabNamesEnum.AGENT_PARAMETERS,
}

export const SakuraUIEnabledEnum = {
  ENABLE_ALL: 'enableAll',
  ENABLE_DEFAULT: 'enableDefault',
  DISABLE_ALL: 'disableAll',
  DISABLE_DEFAULT: 'disableDefault',
}
export enum DialogType {
  COMMENT = 'comment',
  TAGS = 'tags',
  PIN = 'pin',
  EDIT_PIN_COMMENT = 'editPinComment',
  COMPARE = 'compare',
  EDIT_SIDEBAR = 'editSidebar',
  INVESTIGATION_HISTORY = 'investigationHistory',
  METADATA_DETAIL_POPUP = 'metadataDetailPopup',
  BUILD_STATUS_WIDGET = 'buildStatusWidget',
  PIPELINE_INTEGRATION = 'pipelineIntegration',
}
export type AgentFilter =
  | {
      id: AgentId
      typeId?: null
    }
  | {
      typeId: AgentTypeId
      id?: null
    }
export type ProjectOrBuildTypeNode =
  | {
      nodeType: 'all'
      id?: null
      group?: NodeGroup
    }
  | {
      nodeType: 'project'
      id?: ProjectId
      fullPath?: string
      group?: NodeGroup
      parentId?: ProjectId | null | undefined
      internalId?: ProjectInternalId | null | undefined
    }
  | {
      nodeType: 'bt'
      id?: BuildTypeId
      fullPath?: string
      group?: NodeGroup
      parentId?: ProjectId | null | undefined
      internalId?: BuildTypeInternalId | null | undefined
    }
  | {
      nodeType: 'template'
      id: BuildTypeId
      fullPath?: string
      group?: NodeGroup
    }
export const cloneNode = (node: ProjectOrBuildTypeNode): ProjectOrBuildTypeNode => {
  switch (node.nodeType) {
    case 'all':
      return {
        nodeType: 'all',
      }

    case 'project':
      return {
        nodeType: 'project',
        id: node.id,
        fullPath: node.fullPath,
        group: node.group,
      }

    case 'bt':
      return {
        nodeType: 'bt',
        id: node.id,
        fullPath: node.fullPath,
        group: node.group,
      }

    default:
      return {
        nodeType: 'all',
      }
  }
}
export type ActiveEntityProps = {
  projectId?: ProjectId | null | undefined
  buildTypeId?: BuildTypeId | null | undefined
  buildId?: BuildId | null | undefined
  agentId?: AgentId | null | undefined
  agentPoolId?: AgentPoolId | null | undefined
  agentTypeId?: AgentTypeId | null | undefined
  testId?: TestId | null | undefined
  isFavorites?: boolean
}
export type ActiveEntityURLProps = Omit<ActiveEntityProps, 'buildId'> & {
  buildId?: (BuildId | null | undefined) | string
  isAllProjects?: boolean
}
export const getBuildTypeFilter: (arg0: BuildTypeId) => ProjectOrBuildTypeNode = memoize(
  (id: BuildTypeId): ProjectOrBuildTypeNode => ({
    nodeType: 'bt',
    id,
  }),
)
export const getProjectFilter: (projectId: ProjectId) => ProjectOrBuildTypeNode = memoize(
  (id: ProjectId): ProjectOrBuildTypeNode => ({
    nodeType: 'project',
    id,
  }),
)
export const getProjectBuildTypeFilter: (
  props: ActiveEntityProps,
) => ProjectOrBuildTypeNode | null = ({projectId, buildTypeId}) => {
  if (buildTypeId != null) {
    return getBuildTypeFilter(buildTypeId)
  }

  if (projectId != null) {
    return getProjectFilter(projectId)
  }

  return null
}
export const STAR_TAG = '.teamcity.star'
export const STARRED_LOCATOR = `tag:(private:true,owner:current,condition:(value:${STAR_TAG},matchType:equals,ignoreCase:false))`
export const STARRED_LOCATOR_WITHOUT_DEFAULT_FILTER = `defaultFilter:false,${STARRED_LOCATOR}`
export const stateLocators: Record<string, string> = {
  all: '',
  queued: 'state:queued',
  running: 'state:running',
  runningAndFinished: 'state:(running:true,finished:true)',
  failedToStart: 'failedToStart:true',
  canceled: 'canceled:true',
  successful: 'status:SUCCESS',
  failed: 'status:FAILURE,failedToStart:false,canceled:false',
  pinned: 'pinned:true',
  starred: STARRED_LOCATOR,
}
export type ReduxLocatorOptions = {
  locator?: string | null | undefined
  locatorReady?: boolean | null | undefined
  projectBuildtype?: ProjectOrBuildTypeNode | null | undefined
  useRawLocator?: boolean | null | undefined
  agent?: AgentFilter | null | undefined
  agentPattern?: string | null | undefined
  tag?: string | null | undefined
  updating?: boolean | null | undefined
}
export type LocatorOptions = ReduxLocatorOptions & {
  buildState?: string | undefined
  baseLocator?: string | null | undefined
  withRunningAndQueued?: boolean | null | undefined
  branch?: Branch | null | undefined
  pickHistory?: boolean | null | undefined
  runningAndFinished?: boolean
  onlyFavorites?: boolean
  runningCount?: number
  queuedCount?: number
  finishedCount?: number
}
export type BuildLineLayout = {
  showAgent?: boolean
  showDuration?: boolean
  showExactDuration?: boolean
  showBranch?: boolean
  showComment?: boolean
  showPath?: boolean
  showTags?: boolean
  artifactDependenciesMode?: boolean
}
export type DslOption = {
  version: string
  portable: boolean
  current?: boolean
}
export type DslOptions = {
  show: boolean
  version: string
  portable: boolean
}
export type RingSelectAnchorProps = CustomAnchorProps
export const InvestigationStates = {
  TAKEN: 'TAKEN' as 'TAKEN',
  FIXED: 'FIXED' as 'FIXED',
  GIVEN_UP: 'GIVEN_UP' as 'GIVEN_UP',
  NONE: 'NONE' as 'NONE',
}
export const ResolutionType = {
  WHEN_FIXED: 'whenFixed' as 'whenFixed',
  MANUALLY: 'manually' as 'manually',
  AT_TIME: 'atTime' as 'atTime',
}
export type Investigation = {
  id: InvestigationId
  state: $Values<typeof InvestigationStates>
  assignee: User
  resolution: {
    type?: $Values<typeof ResolutionType>
  }
  scope?: Readonly<{
    project?: {
      id: ProjectId
      name: string
    }
    buildTypes?: {
      buildType: Array<{
        id: BuildTypeId
        name: string
      }>
    }
  }>
  assignment: Readonly<{
    timestamp: string
    text?: string
    user?: User
  }>
}
export type BuildStats = WebLinks & {
  id: BuildId
  status: BuildStatus
  state?: BuildState
  queuedDate?: string
  startDate?: string
  finishDate?: string
  timeInQueue: number
  duration: number
}
export type FederationServer = {
  url: FederationServerId
}
export type BranchWithBuilds = Branch & {
  buildTypeId?: BuildTypeId
}
export type NormalizedBranchWithBuilds = Branch & {
  builds: {
    build: Array<BuildId>
  }
}
export type Tab = {
  id: TabId
  title: string
  onlyClassicUI?: boolean
  url?: string
  href?: string
  sakura?: boolean
  js?: Array<string>
  css?: Array<string>
}
export type TabParams = {
  buildId?: BuildId | null | undefined
  buildTypeId?: BuildTypeId | null | undefined
  projectId?: ProjectId | null | undefined
  changeId?: ChangeId | null | undefined
  isEdit?: boolean
  agentId?: AgentId
  agentTypeId?: AgentTypeId
  blockUpdate?: boolean
  branch?: Branch | null | undefined
  personal?: boolean
}
export type TabParamsKey = string
export const AGENTS_TAB_PARAMS_KEY: TabParamsKey = 'agentsDashboard=true'
export type Enhancer<AddProps, OwnProps> = HOC<OwnProps & AddProps, OwnProps>
export type ServerInfo = {
  buildNumber?: string | number
  licenseModeName?: string
  licenseModeDetails?: string
  daysToLicenseExpiration?: number | undefined
  version?: string
  nodeId?: string
  licenseIsCloud?: boolean | undefined
  licenseIsEnterpise?: boolean | undefined
}
export type SnapshotDependenciesIDList = KeyValue<BuildId, ReadonlyArray<BuildId>>
export type LicensingData = {
  maxAgents?: number
  agentsLeft: number
}
export type Dialog = {
  id?: string
  type?: string
  opened?: boolean
  processing?: boolean
  error?: boolean
  data: unknown
}

export type BuildTypeHierarchy =
  | {
      type: 'PROJECT'
      name: string | undefined
      id: ProjectId
      children: Array<BuildTypeHierarchy>
    }
  | {
      type: 'BUILD_TYPE'
      name: string | undefined
      id?: BuildTypeId
    }

export type RingTag = TagType

export enum KillOperationKind {
  CANCEL = 1,
  STOP = 2,
  REMOVE = 3,
}

export const changesTypes = {
  CHANGES: 'changes' as 'changes',
  ARTIFACTS: 'artifacts' as 'artifacts',
}
export type ChangesType = $Values<typeof changesTypes>
export type FilterOptions = {
  changesType?: ChangesType | null | undefined
  versionedSettings?: boolean
  userId?: UserId | string | null | undefined
  username?: string | null | undefined
  comment?: string
  path?: string
  revision?: string
  fromBuildNumber?: string
  toBuildNumber?: string
  projectId?: ProjectId | null | undefined
  showDependenciesChanges?: boolean | null
}
export const BuildStatusType = Object.freeze({
  success: 'success',
  failure: 'failure',
  canceled: 'canceled',
  queued: 'queued',
})
export type BuildStatusTypeType = $Values<typeof BuildStatusType>
